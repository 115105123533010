<template>
  <div>
    <h3>deposit predict log</h3>
    <filter-search-bar @filter_data="filtered_data"> </filter-search-bar>
    <filter-table :filter_data="filter_data" :columns="columns" />
  </div>
</template>

<script>
import { BCard, BCardText } from "bootstrap-vue";
import FilterTable from "./component/FilterTable.vue";
import FilterSearchBar from "./component/FilterSearchBar.vue";

export default {
  data() {
    return { filter_data: [], columns: [] };
  },
  components: {
    BCard,
    BCardText,
    FilterTable,
    FilterSearchBar,
  },
  methods: {
    filtered_data(x) {
      this.filter_data = x.filter_data;
      this.columns = x.columns;
    },
  },
  created() {},
};
</script>

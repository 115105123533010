<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <b-form-group label="อุปกรณ์" label-for="Input2">
            <b-form-input
              v-model="form.search.equipment_name"
              placeholder="Enter Equipment"
              autocomplete="off"
              @keyup.enter="Search()"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="text-right">
        <b-col>
          <b-button v-if="!loading" variant="relief-success" @click="Search()">
            Search
          </b-button>
          <b-button
            variant="relief-success"
            disabled
            class="mr-1"
            v-if="loading"
          >
            <b-spinner small /> &nbsp; Loading...
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormGroup,
  BFormInput,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import { props } from "vue2-dropzone";

export default {
  components: {
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BSpinner,
  },
  created() {
    this.Search();
  },
  mounted() {
    this.$root.$on("log-reload", async () => {
      await this.Search();
    });
  },
  data() {
    return {
      form: {
        search: {
          equipment_name: null,
        },
      },
      loading: false,
    };
  },
  methods: {
    Search() {
      this.loading = true;
      this.$http({
        method: "POST",
        url: `/log/list/filter`,
        data: this.form,
      })
        .then((x) => {
          this.loading = false;
          //   this.filter_data = x.data.data;
          this.$emit("filter_data", {
            filter_data: x.data.data.filter_data,
            columns: x.data.data.columns,
          });
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    clear() {
      this.loading = false;
      this.form.search.order_id = null;
      this.form.search.equipment_name = null;
      this.form.search.customer_info = null;
      this.Search();
    },
  },
};
</script>

<style></style>

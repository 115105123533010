<template>
  <div>
    <vue-good-table :columns="columns" :rows="filter_data">
      <template slot="table-row" slot-scope="props">
        <div v-if="props.column.field === 'date'" class="text-nowrap">
          <span v-if="props.row.check_by_info">
            {{ props.row.check_by_info.first_name }}
            {{ props.row.check_by_info.last_name }} <br />
          </span>

          <span
            >{{ props.row.createdAt | date }} <br />
            <small>{{ props.row.createdAt | time }}</small>
          </span>
        </div>
        <div v-if="props.column.field === 'predict_price'" class="text-nowrap">
          <span>{{ props.row.pred_price | number }}</span>
        </div>

        <div v-if="props.column.field === 'products'" class="text-nowrap">
          <div v-for="(x, index) of props.row.products" :key="index">
            <span>- {{ x.lease_name }}</span> <br />
            <small class="ml-1"
              ><span>{{ x.rental_price | number }} | </span>
              <span>{{ x.deposit_1 | number }} | </span>
              <span>{{ x.lease_price | number }} </span>
            </small>
          </div>
        </div>

        <div v-if="props.column.field === 'sum_total'" class="text-nowrap">
          <span>ราคาอุปกรณ์: {{ props.row.total_lease_price | number }}</span
          ><br />
          <span>ประกัน: {{ props.row.total_dp1_price | number }}</span
          ><br />
          <small>override_price: {{ props.row.override_price | number }}</small>
        </div>
        <div v-if="props.column.field === 'version'" class="text-nowrap">
          <small class="text-white"> {{ props.row.model_version }}</small>
          <br />
          <small class="text-white">from_user: {{ props.row.from_user }}</small>
          <br />
          <b-badge
            class="text-white"
            variant="success"
            size="small"
            v-if="props.row.check_and_book === true"
            >เช็คแล้วจอง</b-badge
          >
        </div>
        <div
          v-if="props.column.field === 'override'"
          class="text-nowrap d-flex align-items-center"
        >
          <input
            v-model="override_deposit[props.row._id]"
            placeholder=""
            class="form-control mr-1"
          />
          <b-button
            @click="
              overrideDeposit(override_deposit[props.row._id], props.row._id)
            "
            size=""
            variant="relief-warning"
            v-b-modal.edit-modal
            v-if="!loading"
            >Edit</b-button
          >
          <b-button
            variant="relief-warning"
            disabled
            size=""
            class=""
            v-if="loading"
          >
            <b-spinner small /> Edit
          </b-button>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { BButton, BSpinner, BBadge } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";

export default {
  components: {
    VueGoodTable,
    BButton,
    BSpinner,
    BBadge,
  },
  props: ["filter_data", "columns"],
  data() {
    return {
      loading: false,
      override_deposit: [],
    };
  },
  methods: {
    overrideDeposit(x, log_id) {
      this.loading = true;
      this.$http({
        method: "POST",
        url: `/log/update`,
        data: {
          log_id: log_id,
          overridedeposit: x,
        },
      }).then((x) => {
        this.loading = false;
        this.$root.$emit("log-reload");
      });
      /// ทำ update deposit log
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        let rounded = Number(x).toFixed(2);
        // Remove the decimal part if it's .00
        if (rounded.endsWith(".00")) {
          rounded = rounded.substring(0, rounded.length - 3);
        }
        return rounded.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    date: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    time: function (date) {
      return moment(date).format("HH:mm:ss");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.badge {
  margin-top: 0.5rem;
}
</style>
